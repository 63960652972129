<template>
  <div id="login" class="w-full">
    <div id="login-form" label-width="100px">
      <h1>{{ $t("login") }}</h1>

      <div class="mt-5">
        <core-input
          v-model="email"
          type="email"
          @keypress.enter.native="login"
          :label="$t('email')"
        />
      </div>
      <div class="mt-2">
        <core-input
          v-model="password"
          type="password"
          @keypress.enter.native="login"
          :label="$t('password')"
        />
      </div>
      <div class="mt-2">
        <core-button class="w-full" type="coolrunner" @click="login">
          {{ $t("login") }}
        </core-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    password: "",
    email: ""
  }),
  methods: {
    login: function() {
      this.$store
        .dispatch("doAuth", { username: this.email, password: this.password })
        .then((res) => {
          if (res == 'SendToSelect') {
            this.$router.push("/accountselect");
          } else {
            if (this.$route.params.nextUrl != null) {
            this.$router.push(this.$route.params.nextUrl);
          } else {
            this.$router.push("/");
          }
          }
        });
    }
  }
};
</script>
